exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cds-tsx": () => import("./../../../src/pages/cds.tsx" /* webpackChunkName: "component---src-pages-cds-tsx" */),
  "component---src-pages-nieuws-tsx": () => import("./../../../src/pages/nieuws.tsx" /* webpackChunkName: "component---src-pages-nieuws-tsx" */),
  "component---src-pages-reservering-tsx": () => import("./../../../src/pages/reservering.tsx" /* webpackChunkName: "component---src-pages-reservering-tsx" */),
  "component---src-templates-cd-template-tsx": () => import("./../../../src/templates/cd-template.tsx" /* webpackChunkName: "component---src-templates-cd-template-tsx" */),
  "component---src-templates-composer-template-tsx": () => import("./../../../src/templates/composer-template.tsx" /* webpackChunkName: "component---src-templates-composer-template-tsx" */),
  "component---src-templates-concert-template-tsx": () => import("./../../../src/templates/concert-template.tsx" /* webpackChunkName: "component---src-templates-concert-template-tsx" */),
  "component---src-templates-concerten-template-tsx": () => import("./../../../src/templates/concerten-template.tsx" /* webpackChunkName: "component---src-templates-concerten-template-tsx" */),
  "component---src-templates-erfgoed-template-tsx": () => import("./../../../src/templates/erfgoed-template.tsx" /* webpackChunkName: "component---src-templates-erfgoed-template-tsx" */),
  "component---src-templates-index-template-tsx": () => import("./../../../src/templates/index-template.tsx" /* webpackChunkName: "component---src-templates-index-template-tsx" */),
  "component---src-templates-page-template-tsx": () => import("./../../../src/templates/page-template.tsx" /* webpackChunkName: "component---src-templates-page-template-tsx" */)
}

